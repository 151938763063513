import { Button, IconButton } from '@chakra-ui/react';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import useIsMobile from 'hooks/useIsMobile';

const ActionsButton = ({ children, onClick }) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <IconButton size="sm" icon={<DotsVerticalIcon />} onClick={onClick} />
  ) : (
    <Button
      variant="outline"
      color="gray.600"
      onClick={onClick}
      textTransform="capitalize"
      fontWeight={600}
      size="sm"
    >
      {children}
    </Button>
  );
};

export default ActionsButton;
