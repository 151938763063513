import {
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PageDrawer from 'components/Dashboard/PageDrawer';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import { getCities, getCountries } from 'api/Dashboard/addresses';
import OptionalIndicator from 'components/shared/OptionalIndicator';
import Input from 'components/shared/Inputs/Input';

const AddressDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValues,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, formState, control, register, watch } = useForm({
    defaultValues: {
      shipping_country: 1,
      ...defaultValues,
    },
  });

  const watchShippingCountry = watch('shipping_country');
  const errors = formState.errors;

  const handleFormSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <PageDrawer
      title={t('addressesPage.addNewAddress')}
      isOpen={isOpen}
      onClose={onClose}
      formId="address-form"
      isLoading={isLoading}
    >
      <form
        id="address-form"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
      >
        <Stack spacing={5} py={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={5}>
            <Controller
              control={control}
              name="shipping_country"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  performSearchRequest={getCountries}
                  entityKey="countries"
                  label={t('country')}
                  placeholder={t('selectCountry')}
                  onChange={(option) => onChange(option?.value)}
                  value={value}
                  error={errors?.shipping_country}
                  required
                  disabled
                />
              )}
            />

            <Controller
              control={control}
              name="city_id"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  performSearchRequest={(searchParams) =>
                    getCities(searchParams, watchShippingCountry)
                  }
                  entityKey="cities"
                  label={t('city')}
                  placeholder={t('selectCity')}
                  onChange={(option) => onChange(option?.value)}
                  value={value}
                  error={errors?.city_id}
                  required
                />
              )}
            />
          </SimpleGrid>

          <Input
            name="district_name"
            label={t('districtName')}
            placeholder={t('enterDistrictName')}
            register={register}
            errors={errors}
            required="thisFieldIsRequired"
          />

          <Input
            name="street_name"
            label={t('streetName')}
            placeholder={t('enterStreetName')}
            register={register}
            errors={errors}
            required="thisFieldIsRequired"
          />

          <Input
            name="building_number"
            label={t('buildingNumber')}
            placeholder={t('enterBuildingNumber')}
            register={register}
            errors={errors}
          />

          <Input
            name="zip_code"
            label={t('zipCode')}
            placeholder={t('enterZipCode')}
            register={register}
            errors={errors}
          />

          <FormControl height="100%">
            <FormLabel optionalIndicator={<OptionalIndicator />} mb={0}>
              {t('additionalDetails')}
            </FormLabel>
            <Text fontSize="xs" color="gray.500" fontWeight={500} mb={2}>
              {t('addressDetailsHelpText')}
            </Text>
            <Textarea
              width="full"
              height="100%"
              minHeight="130px"
              placeholder={t('enterAddressDetails')}
              borderRadius="xl"
              bg="gray.50"
              fontSize="md"
              padding={4}
              _placeholder={{
                color: 'gray.500',
              }}
              {...register('additional_details')}
            />
          </FormControl>
        </Stack>
      </form>
    </PageDrawer>
  );
};

export default AddressDrawer;
