export const isNumber = (value) => typeof value === 'number';

export const formatCentsToSAR = (valueInCents) => {
  if (!isNumber(valueInCents)) {
    return valueInCents;
  }

  return roundNumber(valueInCents / 100);
};

export const roundNumber = (value, locale = 'en', maximumFractionDigits = 2) =>
  (Math.round(value * 100) / 100).toLocaleString(locale, {
    maximumFractionDigits,
  });
