import { Flex, useDisclosure } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import useScrollToTop from 'hooks/useScrollToTop';
import { useFeatureFlags } from 'context/FeatureFlags';

import { AbilityContext } from 'context/AbilityContext';
import { defineAbilitiesFor } from 'utils/abilities';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import Onboarding from 'pages/Onboarding/Onboarding';
import { css, Global } from '@emotion/react';
import UnverifiedOrganizationAlert from 'components/Dashboard/UnverifiedOrganizationAlert';
import { useUnverifiedOrganizationAlert } from 'context/UnverifiedOrganizationAlert';
import { useQuery } from 'react-query';
import { getOrganization, getUser } from 'api/auth';
import { usePaymentDue } from 'context/PaymentDueContext';
import { useEffect } from 'react';
import AccountLockedModal from 'components/Dashboard/AccountLockedModal';
import UnpaidInvoiceAlert from 'components/Dashboard/UnpaidInvoiceAlert';
import UnpaidInvoiceModal from 'components/Dashboard/UnpaidInvoiceModal';
import PaymentFailedModal from 'components/Dashboard/PaymentFailedModal';
import PaymentSuccessAlert from 'components/Dashboard/PaymentSuccessAlert';
import pages from 'constants/dashboardLayoutPages';

const SIDEBAR_SIZE = '250px';
const NAVBAR_HEIGHT = { base: 16, md: '97px' };
const disableAppStyle = { pointerEvents: 'none', opacity: 0.5 };

const getActiveChildPage = (page, pathname, id) =>
  page?.children?.find((childPage) => {
    if (id) {
      return page.path + childPage.path.replace(':id', id) === pathname;
    }
    return page.path + childPage.path === pathname;
  });

const DashboardLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = useRef(null);
  const { isEnabled: isFeatureEnabled } = useFeatureFlags();

  useScrollToTop(contentRef);

  const {
    isOpen: isSidebarOpen,
    onClose: onSidebarClose,
    onToggle: onSidebarToggle,
  } = useDisclosure();

  const {
    isOpen: isPaymentFailModalOpen,
    onClose: onPaymentFailModalClose,
    onOpen: onPaymentFailModalOpen,
  } = useDisclosure();

  const {
    isOpen: isPaymentSuccessAlertOpen,
    onClose: onPaymentSuccessAlertClose,
    onOpen: onPaymentSuccessAlertOpen,
  } = useDisclosure();

  const { showUnverifiedOrganizationAlert, hideUnverifiedOrganizationAlert } =
    useUnverifiedOrganizationAlert();

  const { isPaymentDueForAccountLocked, setPaymentDueDays } = usePaymentDue();

  const paymentSuccessParam = searchParams.get('payment_success');
  const errorCodeParam = searchParams.get('error_code');
  const isPaymentSuccess = paymentSuccessParam === 'true';
  const isPaymentFailed = paymentSuccessParam === 'false';

  const user = getLocalStorage('user') ?? {};

  const { refetch: refetchUser } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      if (data.organization?.verified === false) {
        showUnverifiedOrganizationAlert();
      } else {
        hideUnverifiedOrganizationAlert();
      }

      setLocalStorage('user', data);
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading: isOrganizationLoading } = useQuery(
    ['organization'],
    (id) => getOrganization(user.id),
    {
      onSuccess: (data) => {
        setPaymentDueDays(data.invoicing_status?.days_overdue);
      },
    }
  );

  const isOnboardingFinished = user?.onboarding_finished;

  const featureEnabledPages = pages.filter(
    (page) => !page.featureFlag || isFeatureEnabled(page.featureFlag)
  );

  const activePage = featureEnabledPages.find(
    (page) =>
      page.path === pathname ||
      getActiveChildPage(page, pathname, id) ||
      pathname.includes(`${page.path}/${id}`)
  );

  const activeChildPage = getActiveChildPage(activePage, pathname, id);

  const pageTitle = t(
    activeChildPage?.pageTitle ?? activePage?.pageTitle ?? activePage?.name
  );

  const linkItems = featureEnabledPages.filter(
    (page) => !page.isChildPage && (!page.adminsOnly || user.role === 'admin')
  );

  const isCreatePage = !!activeChildPage?.isCreate;

  const ability = defineAbilitiesFor(user);

  useEffect(() => {
    if (isPaymentDueForAccountLocked) {
      navigate('/settings/billing');
    }
  }, [isPaymentDueForAccountLocked, navigate]);

  useEffect(() => {
    if (isPaymentFailed) {
      onPaymentFailModalOpen();
      return;
    }

    if (isPaymentSuccess) {
      onPaymentSuccessAlertOpen();
    }
  }, [
    isPaymentFailed,
    isPaymentSuccess,
    onPaymentFailModalOpen,
    onPaymentSuccessAlertOpen,
  ]);

  const clearSearchParams = () => {
    setSearchParams({}, { replace: true });
  };

  return (
    <AbilityContext.Provider value={ability}>
      <Flex
        sx={{
          height: '100vh',
          flexDirection: 'row',
          borderTop: '1px solid',
          borderColor: 'gray.200',
          ...(isPaymentDueForAccountLocked && disableAppStyle),
        }}
      >
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={onSidebarClose}
          onToggle={onSidebarToggle}
          size={SIDEBAR_SIZE}
          navbarHeight={NAVBAR_HEIGHT}
          linkItems={linkItems}
          activeLink={activePage}
        />
        <Flex flex="1" height="100%" flexDirection="column" w="0">
          <Navbar
            onToggle={onSidebarToggle}
            height={NAVBAR_HEIGHT}
            pageTitle={pageTitle}
            isCreatePage={isCreatePage}
          />

          <UnverifiedOrganizationAlert />
          {isPaymentSuccess && (
            <PaymentSuccessAlert
              isOpen={isPaymentSuccessAlertOpen}
              onClose={() => {
                onPaymentSuccessAlertClose();
                clearSearchParams();
              }}
            />
          )}
          <UnpaidInvoiceAlert />

          <Flex
            py={activePage?.noPadding ? 0 : { base: 4, md: 6 }}
            px={activePage?.noPadding ? 0 : { base: 4, md: 8 }}
            sx={{
              // adds space for Intercom widget so it doesn't override other elements
              paddingBottom: '100px !important',
            }}
            bg="gray.50"
            flex="1"
            flexDirection="column"
            overflowY="auto"
            ref={contentRef}
          >
            <Outlet context={{ pageTitle }} />

            {!isOnboardingFinished && (
              <>
                {/* to layer the intercom button above the onboarding modal */}
                <Global
                  styles={css`
                    .intercom-lightweight-app-launcher.intercom-launcher,
                    .intercom-lightweight-app,
                    .intercom-1qaopps,
                    .intercom-messenger-frame {
                      z-index: 1402 !important;
                    }
                  `}
                />
                <Onboarding onSuccess={refetchUser} />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      <AccountLockedModal isOpen={isPaymentDueForAccountLocked} />
      {!isOrganizationLoading && <UnpaidInvoiceModal />}
      {isPaymentFailed && (
        <PaymentFailedModal
          onClose={() => {
            onPaymentFailModalClose();
            clearSearchParams();
          }}
          isOpen={isPaymentFailModalOpen}
          errorCode={errorCodeParam}
        />
      )}
    </AbilityContext.Provider>
  );
};

export default DashboardLayout;
