import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'utils/i18n';
import 'utils/sentry';
import AppWithProviders from 'AppWithProviders';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { isProduction } from 'utils/environment';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWithProviders />
  </React.StrictMode>
);

if (isProduction) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
}
