import { Stack, Skeleton, SimpleGrid } from '@chakra-ui/react';

const OrderDetailsSkeleton = () => {
  return (
    <Stack spacing={6}>
      <Skeleton height="40px" width="200px" />

      <Skeleton height="120px" borderRadius="xl" />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <Skeleton height="200px" borderRadius="xl" />
        <Skeleton height="200px" borderRadius="xl" />
      </SimpleGrid>

      <Skeleton height="300px" borderRadius="xl" />

      <Skeleton height="300px" borderRadius="xl" />

      <Skeleton height="300px" borderRadius="xl" />

      <Skeleton height="200px" borderRadius="xl" />
    </Stack>
  );
};

export default OrderDetailsSkeleton;
