import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  IconButton,
  InputLeftElement,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/icons/eye-slash.svg';
import useLanguage from 'hooks/useLanguage';
import { inputSizes } from 'constants/theme';
import OptionalIndicator from '../OptionalIndicator';

const getNestedError = (name, errors) => {
  const keys = name.split('.');
  let current = errors;

  for (const key of keys) {
    if (Array.isArray(current)) {
      const index = parseInt(key, 10);
      current = current[index];
    } else {
      current = current[key];
    }

    if (current === undefined) {
      return undefined;
    }
  }

  return current;
};

const PasswordIconButton = ({ show, onClick }) => (
  <IconButton
    onClick={onClick}
    icon={
      show ? (
        <EyeIcon width="24px" height="24px" />
      ) : (
        <EyeSlashIcon width="24px" height="24px" />
      )
    }
  />
);

const Input = ({
  name,
  errors,
  register,
  defaultValue,
  label,
  placeholder,
  pattern,
  required,
  minLength,
  maxLength,
  size = 'md',
  validate,
  type = 'text',
  disabled,
  maxWidth,
  min = 0,
}) => {
  const { t } = useTranslation();

  const { language } = useLanguage();

  const isPassword = type === 'password';

  const [show, setShow] = useState(!isPassword);

  const handleClick = () => setShow(!show);

  const formProps = register(name, {
    required,
    pattern,
    minLength,
    maxLength,
    validate,
    min,
  });

  const [timeoutId, setTimeoutId] = useState(null);

  const handleChange = useCallback(
    (e) => {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          formProps.onChange(e);
        }, 500)
      );
    },
    [formProps, timeoutId]
  );

  const error = getNestedError(name, errors);

  return (
    <FormControl as="fieldset" isInvalid={error} isRequired={required}>
      <FormLabel
        htmlFor={name}
        mb="2"
        color="gray.700"
        textTransform="capitalize"
        requiredIndicator={false}
        optionalIndicator={<OptionalIndicator />}
      >
        {label}
      </FormLabel>
      <InputGroup size={size} maxWidth={maxWidth}>
        <ChakraInput
          id={name}
          name={name}
          placeholder={placeholder}
          {...formProps}
          onChange={handleChange}
          columns={5}
          defaultValue={defaultValue}
          size={size}
          type={show ? (isPassword ? 'text' : type) : 'password'}
          disabled={disabled}
          min={min?.value ?? min}
          minHeight={inputSizes[size]}
          lineHeight={10}
        />

        {isPassword && (
          <>
            {language === 'ar' ? (
              <InputLeftElement>
                <PasswordIconButton onClick={handleClick} show={show} />
              </InputLeftElement>
            ) : (
              <InputRightElement>
                <PasswordIconButton onClick={handleClick} show={show} />
              </InputRightElement>
            )}
          </>
        )}
      </InputGroup>
      {error && (
        <FormErrorMessage>
          {error.type === 'minLength'
            ? `${t(error.message)} ${minLength.value}`
            : error.type === 'maxLength'
            ? `${t(error.message)} ${maxLength.value}`
            : t(error.message)}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default Input;
