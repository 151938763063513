import {
  FormLabel,
  Box,
  SimpleGrid,
  Stack,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { getQuoteCities } from 'api/onboarding';
import Select from 'components/Dashboard/Select';
import { useQuery } from 'react-query';
import useLanguage from 'hooks/useLanguage';
import { agreementTypeEnum } from 'constants/dashboard';
import RadioCardGroup from 'components/shared/Inputs/RadioCardGroup/RadioCardGroup';
import StepperInput from 'components/shared/Inputs/StepperInput';
import { addDayToDate, utcStringToDate } from 'utils/date';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { useEffect } from 'react';
import { InventoryTypeLabel } from './InventoryTypeLabel';
import { ReactComponent as PalletizedInfo } from 'assets/images/palletized-info.svg';
import { ReactComponent as BulkyGoodsInfo } from 'assets/images/bulky-goods-info.svg';
import {
  getFormSelectDefaultValue,
  handleMultiSelectChange,
} from 'utils/select';

const QuoteForm = ({ onSubmit, defaultValues = {} }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';

  const { data: citiesData, isLoading } = useQuery(
    'quote-cities',
    getQuoteCities
  );

  const formDefaultValues = {
    ...defaultValues,
    date: utcStringToDate(defaultValues.date),
    ending_date: utcStringToDate(defaultValues.ending_date),
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const watchInventoryType = watch('inventory_type');
  const watchAgreementType = watch('type_of_agreement');
  const watchStartDate = watch('date');
  const watchEndDate = watch('ending_date');

  const showPalletsInput =
    watchInventoryType === 'ready_to_palletize' ||
    watchInventoryType === 'palletized';

  const showSpaceInput = watchInventoryType === 'bulky_goods';

  useEffect(() => {
    if (
      watchInventoryType === 'ready_to_palletize' ||
      watchInventoryType === 'palletized'
    ) {
      setValue('number_of_sqm', '0');
      return;
    }

    setValue('number_of_pallets', '0');
  }, [watchInventoryType, setValue]);

  const cityOptions = citiesData?.map(({ id, name, nameLocale }) => ({
    value: id,
    label: isAR ? nameLocale : name,
  }));

  const sfdaLicenseOptions = [
    { label: t('noSFDALicense'), value: undefined },
    { label: t('warehouseLicenses.sfda_food'), value: 'sfda_food' },
    { label: t('warehouseLicenses.sfda_pharma'), value: 'sfda_pharma' },
    { label: t('warehouseLicenses.sfda_medical'), value: 'sfda_medical' },
    { label: t('warehouseLicenses.sfda_pet_food'), value: 'sfda_pet_food' },
    { label: t('warehouseLicenses.sfda_cosmetics'), value: 'sfda_cosmetics' },
    { label: t('warehouseLicenses.sfda_pesticides'), value: 'sfda_pesticides' },
  ];

  const inventoryTypeOptions = [
    {
      label: (
        <InventoryTypeLabel
          label={t('pallets')}
          illustration={<PalletizedInfo />}
          description={[
            {
              title: t('pallet'),
              content: t('inventoryTypeInfo.palletContent'),
            },
            {
              title: t('inventoryTypeInfo.dimensionsTitle'),
              content: t('inventoryTypeInfo.dimensionsContent'),
            },
            {
              title: t('inventoryTypeInfo.idealTitle'),
              content: t('inventoryTypeInfo.idealContent'),
            },
          ]}
        />
      ),
      value: 'palletized',
    },
    {
      label: (
        <InventoryTypeLabel
          label={t('freeSpace')}
          illustration={<BulkyGoodsInfo />}
          description={[
            {
              title: t('inventoryTypeInfo.floorAreaTitle'),
              content: t('inventoryTypeInfo.floorAreaContent'),
            },
          ]}
        />
      ),
      value: 'bulky_goods',
    },
  ];

  const agreementTypeOptions = Object.keys(agreementTypeEnum).map((key) => ({
    label: t(`agreementType.${key}`),
    value: agreementTypeEnum[key],
  }));

  const warehouseTypeOptions = [
    {
      label: t('dry'),
      value: 'dry',
    },
    {
      label: t('tempControlled'),
      value: 'air_conditioned',
    },
    {
      label: t('chilled'),
      value: 'refrigerated',
    },
    {
      label: t('frozen'),
      value: 'frozen',
    },
  ];

  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="quoteForm" noValidate>
      <Stack spacing={6}>
        <Controller
          control={control}
          name="city_id"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange } }) => (
            <Select
              label={t('city')}
              placeholder={
                <Text textTransform="none">
                  {t('pleaseSelectCityToStoreIn')}
                </Text>
              }
              options={cityOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.city_id}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.city_id,
                cityOptions
              )}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="warehouse_type"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('warehouseType')}
              placeholder={
                <Text textTransform="none">{t('selectWarehouseType')}</Text>
              }
              options={warehouseTypeOptions}
              onChange={(values, actionMeta) => {
                handleMultiSelectChange({
                  values,
                  actionMeta,
                  onChange,
                });
              }}
              value={value}
              error={errors?.warehouse_type}
              required
              isMulti
            />
          )}
        />
        <Controller
          control={control}
          name="sfda_license_type"
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('licensing')}
              placeholder={
                <Text textTransform="none">{t('requiredLicensing')}</Text>
              }
              options={sfdaLicenseOptions}
              onChange={(values, actionMeta) => {
                handleMultiSelectChange({
                  values,
                  actionMeta,
                  onChange,
                });
              }}
              value={value}
              error={errors?.sfda_license_type}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.sfda_license_type,
                sfdaLicenseOptions
              )}
              isMulti
            />
          )}
        />

        <RadioCardGroup
          name="inventory_type"
          label={t('storageType')}
          options={inventoryTypeOptions}
          register={register}
          required="thisFieldIsRequired"
          errors={errors}
          defaultValue={formDefaultValues.inventory_type}
          columns={1}
          withCheckMark
        />

        {watchInventoryType && (
          <Box spacing={4}>
            <FormLabel>
              {t(
                showPalletsInput
                  ? 'howManyPalletPositionsDoYouExpectToReserve'
                  : 'howMuchFreeSpaceInSqmDoYouExpectToReserve'
              )}
            </FormLabel>

            {showPalletsInput && (
              <StepperInput
                name="number_of_pallets"
                label={t('pallets')}
                register={register}
                required="thisFieldIsRequired"
                min={10}
                minErrorMessage="minIs"
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                defaultValue={formDefaultValues.number_of_pallets}
              />
            )}

            {showSpaceInput && (
              <StepperInput
                name="number_of_sqm"
                label={t('sqM')}
                register={register}
                required="thisFieldIsRequired"
                min={10}
                minErrorMessage="minIs"
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                defaultValue={formDefaultValues.number_of_sqm}
              />
            )}
          </Box>
        )}

        <Controller
          control={control}
          name="type_of_agreement"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('duration')}
              placeholder={t('selectDuration')}
              options={agreementTypeOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.type_of_agreement}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.type_of_agreement,
                agreementTypeOptions
              )}
              required
            />
          )}
        />

        <SimpleGrid columns={2} spacing={4}>
          <DatePicker
            name="date"
            label={t('onboarding.startingDate')}
            register={register}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            required="thisFieldIsRequired"
            minDate={new Date()}
            maxDate={watchEndDate}
            formValue={watchStartDate}
            control={control}
          />

          {watchAgreementType === agreementTypeEnum.specific_duration && (
            <DatePicker
              name="ending_date"
              label={t('endingDate')}
              register={register}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
              required={'thisFieldIsRequired'}
              minDate={addDayToDate(watchStartDate) || addDayToDate(new Date())}
              formValue={watchEndDate}
              control={control}
            />
          )}
        </SimpleGrid>
      </Stack>
    </form>
  );
};

export default QuoteForm;
