import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Button,
  Stack,
  HStack,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Select from 'components/Dashboard/Select';
import Input from 'components/shared/Inputs/Input';
import { getCustomers } from 'api/Dashboard/fulfillment/customers';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import OrderSKUCart from 'components/Dashboard/Fulfillment/Orders/OrderSKUCart';
import AddressDetails from 'components/Dashboard/Fulfillment/Orders/AddressDetails';
import { createOrder } from 'api/Dashboard/fulfillment/orders';
import AddressDrawer from 'components/Dashboard/Fulfillment/Orders/AddressDrawer';
import { addCustomerAddress } from 'api/Dashboard/fulfillment/customers';

export const ECOMMERCE_CHANNELS = Object.freeze({
  OFFLINE: 'offline',
  SHOPIFY: 'shopify',
  ZID: 'zid',
  SALLA: 'salla',
});

export const ecommerceChannelOptions = [
  { value: ECOMMERCE_CHANNELS.OFFLINE, label: 'Offline' },
  { value: ECOMMERCE_CHANNELS.SHOPIFY, label: 'Shopify' },
  { value: ECOMMERCE_CHANNELS.ZID, label: 'Zid' },
  { value: ECOMMERCE_CHANNELS.SALLA, label: 'Salla' },
];

const OrderCreate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isAddressDrawerOpen,
    onOpen: onAddressDrawerOpen,
    onClose: onAddressDrawerClose,
  } = useDisclosure();

  const [skus, setSKUs] = useState([]);

  const {
    handleSubmit,
    formState,
    control,
    register,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      shipping_country: 1,
    },
  });

  const { mutate: createOrderMutation, isLoading: createOrderLoading } =
    useMutation(createOrder, {
      onSuccess: (response) => {
        toast({
          title: t('orderCreatedSuccessfully'),
          status: 'success',
        });

        navigate(`/fulfillment/orders/${response?.data?.id}`);
      },
      onError: (error) => {
        toast({
          title: error?.response?.data?.errors[0] || t('somethingWentWrong'),
          status: 'error',
          isClosable: true,
        });
      },
    });

  const watchAddressId = watch('address_id');
  const watchCustomerId = watch('customer_id');

  const fulfillmentCustomersOptions = queryClient.getQueryData(
    'fulfillment-customers-options'
  );
  const selectedCustomer = fulfillmentCustomersOptions?.data?.find(
    (customer) => customer.id === watchCustomerId
  );

  const errors = formState.errors;

  const handleSKUDelete = (id) => {
    setSKUs((prevValue) => prevValue.filter((sku) => sku.id !== id));
  };

  const handleSkuChange = (id, { key, value }) => {
    setSKUs((prevValue) =>
      prevValue.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  const handleSkuSubmit = (selectedSKU) => {
    setSKUs((prevValue) => [...prevValue, selectedSKU]);
  };

  const handleAddressSubmit = (address) => {
    setValue('address_id', address.id);
    trigger('address_id');
  };

  const { mutate: addAddress, isLoading: isAddAddressLoading } = useMutation(
    (address) => addCustomerAddress(watchCustomerId, address),
    {
      onSuccess: async (response) => {
        toast({
          title: t('addressAddedSuccessfully'),
          status: 'success',
        });
        await queryClient.refetchQueries(
          `customer-addresses-${watchCustomerId}`
        );
        handleAddressSubmit(response.data?.data);
        onAddressDrawerClose();
      },
      onError: (error) => {
        toast({
          title: error?.response?.data?.message || t('somethingWentWrong'),
          status: 'error',
        });
      },
    }
  );

  const handleAddressDrawerSubmit = (data) => {
    const payload = {
      city_id: data.city_id,
      district_name: data.district_name,
      street_name: data.street_name,
      building_number: data.building_number,
      zip_code: data.zip_code,
      additional_details: data.additional_details,
    };

    addAddress(payload);
  };

  const handleSubmitForm = async (data) => {
    if (skus.length === 0) {
      toast({
        title: t('skuError'),
        status: 'error',
      });
      return;
    }

    const payload = {
      customer_id: data.customer_id,
      address_id: data.address_id,
      channel: data.channel,
      channel_order_id: data.channel_order_id,
      fulfillment_order_skus_attributes: skus.map((sku) => ({
        sku_id: sku.id,
        quantity: sku.quantity,
        unit_base_price: +sku.price,
      })),
    };

    createOrderMutation(payload);
  };

  return (
    <>
      <form
        id="order-form"
        onSubmit={handleSubmit(handleSubmitForm)}
        noValidate
      >
        <Stack spacing={6}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={6}>
            <Stack spacing={6}>
              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('customers.customerDetails')} />
                </CardHeader>
                <CardBody>
                  <Stack spacing={5}>
                    <Controller
                      control={control}
                      name="customer_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <AsyncSelect
                          performSearchRequest={async (searchParams) => {
                            const data = await getCustomers(searchParams);

                            const customerOptions = data?.data?.map(
                              (customer) => ({
                                id: customer.id,
                                name: customer.full_name,
                                email: customer.email,
                                phone: customer.phone,
                              })
                            );

                            return { data: customerOptions };
                          }}
                          entityKey="fulfillment-customers-options"
                          label={t('customer')}
                          placeholder={t('pleaseSelectCustomer')}
                          onChange={(option) => onChange(option?.value)}
                          value={value}
                          error={errors?.customer_id}
                          required
                        />
                      )}
                    />

                    {!!selectedCustomer && (
                      <HStack spacing={5} alignItems="flex-start">
                        <Stack width="50%" spacing={2}>
                          <Text fontWeight={500} color="gray.700">
                            {t('email')}
                          </Text>
                          <Text fontWeight={600}>
                            {selectedCustomer?.email}
                          </Text>
                        </Stack>
                        <Stack width="50%" spacing={2}>
                          <Text fontWeight={500} color="gray.700">
                            {t('phone')}
                          </Text>
                          <Text fontWeight={600}>
                            {selectedCustomer?.phone}
                          </Text>
                        </Stack>
                      </HStack>
                    )}
                  </Stack>
                </CardBody>
              </Card>

              <Card size="lg" height="100%">
                <CardHeader>
                  <SectionTitle title={t('orders.orderDetails')} />
                </CardHeader>
                <CardBody>
                  <Stack spacing={6}>
                    <Controller
                      control={control}
                      name="channel"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('channel')}
                          placeholder={t('pleaseSelectChannel')}
                          options={ecommerceChannelOptions}
                          arLabelKey="labelLocale"
                          onChange={({ value }) => onChange(value)}
                          error={errors?.channel}
                          required
                        />
                      )}
                    />
                    <Input
                      name="channel_order_id"
                      label={t('channelOrderId')}
                      placeholder={t('pleaseEnterChannelOrderId')}
                      register={register}
                      errors={errors}
                    />
                  </Stack>
                </CardBody>
              </Card>
            </Stack>

            <AddressDetails
              addressId={watchAddressId}
              control={control}
              errors={errors}
              isDisabled={!watchCustomerId}
              customerId={watchCustomerId}
              onAddNewClick={onAddressDrawerOpen}
            />
          </SimpleGrid>

          <OrderSKUCart
            skus={skus}
            onSKUDelete={handleSKUDelete}
            onSKUChange={handleSkuChange}
            onSKUSubmit={handleSkuSubmit}
          />
        </Stack>

        <CreateButtonsContainer>
          <Button
            colorScheme="red"
            variant="outline"
            bgColor="white"
            size="lg"
            minWidth="124px"
            as={Link}
            to="/fulfillment/orders"
            isDisabled={createOrderLoading}
          >
            {t('cancel')}
          </Button>
          <Button
            colorScheme="primary"
            size="lg"
            minWidth="124px"
            type="submit"
            textTransform="capitalize"
            isLoading={createOrderLoading}
          >
            {t('confirm')}
          </Button>
        </CreateButtonsContainer>
      </form>

      {isAddressDrawerOpen && (
        <AddressDrawer
          isOpen={isAddressDrawerOpen}
          onClose={onAddressDrawerClose}
          onSubmit={handleAddressDrawerSubmit}
          isLoading={isAddAddressLoading}
        />
      )}
    </>
  );
};

export default OrderCreate;
