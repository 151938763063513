import { Stack, SimpleGrid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Input from 'components/shared/Inputs/Input';
import { useTranslation } from 'react-i18next';
import { emailRegex } from 'utils/regex';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { flattenErrorObject } from 'utils/formError';

const CustomerForm = ({
  defaultValues,
  onSubmit,
  isLoading,
  backendErrors = [],
}) => {
  const { t } = useTranslation();

  const { handleSubmit, formState, register } = useForm({
    defaultValues,
  });

  const errors = { ...formState.errors, ...flattenErrorObject(backendErrors) };

  const isEdit = !!defaultValues;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="customerForm">
      <Stack spacing={5}>
        <SimpleGrid columns={2} spacing={5}>
          {!isEdit && (
            <>
              <Input
                name="first_name"
                label={t('firstName')}
                register={register}
                errors={errors}
                placeholder={`${t('e.g')}: ${t('firstNameExample')}`}
                size="lg"
                required="thisFieldIsRequired"
              />
              <Input
                name="last_name"
                label={t('lastName')}
                register={register}
                errors={errors}
                placeholder={`${t('e.g')}: ${t('lastNameExample')}`}
                size="lg"
                required="thisFieldIsRequired"
              />
            </>
          )}
          <Input
            name="email"
            label={t('email')}
            placeholder={`${t('e.g')}: ${t('email@gmail.com')}`}
            register={register}
            errors={errors}
            pattern={{
              value: emailRegex,
              message: 'validation.invalidEmail',
            }}
            size="lg"
            required="thisFieldIsRequired"
          />
          <Input
            name="phone"
            label={t('phoneNumber')}
            placeholder={`${t('e.g')}: +966112212222`}
            register={register}
            errors={errors}
            required="thisFieldIsRequired"
            validate={(value) =>
              isValidPhoneNumber(value, 'SA') || 'validation.invalidNumber'
            }
            size="lg"
          />
        </SimpleGrid>
      </Stack>
    </form>
  );
};

export default CustomerForm;
