export const generateCustomerAddress = (address, isCustomerPage) => {
  const addressFields = isCustomerPage
    ? [
        `${address.building_number} ${address.street_name}`,
        address.district_name,
        address.city.name,
        address.city.country_name,
      ]
    : [
        `${address.shipping_building_number} ${address.shipping_street_name}`,
        address.shipping_district_name,
        address.shipping_city,
        address.shipping_country,
      ];

  return addressFields.join(', ');
};
