import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Card as ChakraCard,
  CardHeader,
  CardBody,
  Divider,
  Text,
  Flex,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import Tooltip from 'components/shared/Tooltip';
import Chart from './Chart';
import useLanguage from 'hooks/useLanguage';
import useIsMobile from 'hooks/useIsMobile';

const Card = ({ title, icon, hint, data, chartData }) => {
  const isMobile = useIsMobile();
  const { language } = useLanguage();

  return (
    <ChakraCard
      borderRadius="xl"
      width="100%"
      style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}
    >
      <CardHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={6}
      >
        <Flex gap="2" alignItems="center">
          {icon}
          <Text fontWeight={500} textTransform="capitalize">
            {title}
          </Text>
        </Flex>

        {hint && !isMobile && (
          <Tooltip label={hint} icon={<InfoOutlineIcon />} placement="top" />
        )}
      </CardHeader>

      <CardBody pt="0" px={6}>
        <Divider mb="4" />
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <GridItem colSpan={{ base: 5, md: chartData ? 3 : 5 }}>
            <Flex direction="row" gap="6">
              <Flex direction="column" gap="2">
                {data?.map(({ value }, index) => (
                  <Text fontSize="2xl" fontWeight="500" key={index}>
                    {value}
                  </Text>
                ))}
              </Flex>

              <Flex direction="column" justifyContent="space-between" gap="2">
                {data?.map(({ label }, index) => (
                  <Flex
                    key={index}
                    height="100%"
                    alignItems="center"
                    fontSize="sm"
                  >
                    <Text color="gray.600" textTransform="capitalize">
                      {label}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </GridItem>

          {chartData && (
            <GridItem colSpan={2} display={{ base: 'none', md: 'block' }}>
              <Flex justifyContent="end" alignItems="end" height="100%">
                <Box maxHeight="70px" maxWidth="120px">
                  <Chart
                    options={{
                      hideAxes: true,
                      hideLegend: true,
                    }}
                    data={chartData}
                  />
                </Box>
              </Flex>
            </GridItem>
          )}
        </Grid>
      </CardBody>
    </ChakraCard>
  );
};

export default Card;
