import { Card, Flex, Stack, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as TemperatureIcon } from 'assets/icons/temperature-minus.svg';
import styled from '@emotion/styled';
import { storageTypes } from 'pages/Dashboard/Warehouses/Warehouses';
import useIsMobile from 'hooks/useIsMobile';

const StyledLocationIcon = styled(LocationIcon)`
  path {
    fill: transparent;
    stroke: #4a5568;
  }
`;

const SuggestedWarehousesDesktopItem = ({
  warehouse,
  warehousesSearch = {},
  onClick,
  isSelected,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';
  const isMobile = useIsMobile();

  const {
    name,
    alias_name,
    address,
    condition_types,
    open_time,
    close_time,
    closed_days,
  } = warehouse;

  const closeDaysTranslated = closed_days.map((day) => t(`weekDays.${day}`));

  const closedTime = `${open_time} - ${close_time} ${t(
    'except'
  )} ${closeDaysTranslated.join(', ')}`;

  const conditionTypes = [...new Set(condition_types)];

  const translatedConditionType = conditionTypes.map((type) => {
    const storageType = storageTypes[type];
    return t(storageType.label);
  });

  return (
    <Card
      paddingX={4}
      paddingY={5}
      flexDirection="row"
      gap={7}
      onClick={onClick}
      bg={isSelected && !isMobile ? 'white' : 'transparent'}
      cursor={isSelected ? undefined : 'pointer'}
      borderRadius="2xl"
      _hover={{
        bg: isSelected ? 'white' : 'gray.200',
      }}
    >
      <Flex flex={1} flexDirection="column" gap={4}>
        <SectionTitle title={alias_name ?? name} hideDivider />

        <Flex fontSize="sm" fontWeight={500} color="gray.700" gap={8}>
          <Stack spacing={3} minWidth="250px">
            <HStack alignItems="center" spacing={2}>
              <StyledLocationIcon width="24px" height="24px" />
              <Text>{isAR ? address.city.nameLocale : address.city.name}</Text>
            </HStack>

            <HStack spacing={2}>
              <TemperatureIcon width="24px" height="24px" />
              <Text>{translatedConditionType.join(', ')}</Text>
            </HStack>

            <HStack alignItems="center" spacing={2}>
              <ClockIcon width="24px" height="24px" />
              <Text as="span">{closedTime}</Text>
            </HStack>
          </Stack>
        </Flex>
      </Flex>
    </Card>
  );
};

export default SuggestedWarehousesDesktopItem;
