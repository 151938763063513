import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = (elementRef) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!elementRef?.current) return;

    elementRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname, elementRef]);
};

export default useScrollToTop;
