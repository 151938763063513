import { createSKUGroup } from 'api/Dashboard/skuGroups';
import SKUGroupsFormPage from 'components/Dashboard/SKUGroups/SKUGroupsFormPage';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const SKUGroupsCreate = () => {
  const navigate = useNavigate();

  const { mutate: createSKUGroupMutation, isLoading: isCreateSKULoading } =
    useMutation(createSKUGroup, {
      onSuccess: (response) => {
        navigate(`/sku-groups/${response.data.id}`);
      },
    });

  return (
    <SKUGroupsFormPage
      onSubmit={createSKUGroupMutation}
      isLoading={isCreateSKULoading}
    />
  );
};

export default SKUGroupsCreate;
