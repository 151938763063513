import {
  Box,
  Flex,
  Button,
  Stack,
  Heading,
  InputGroup,
  Input,
  Text,
  SimpleGrid,
  useBoolean,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  Switch,
  Show,
  Hide,
  CloseButton,
  Popover,
  useDisclosure,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  useOutsideClick,
} from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Table from 'components/Dashboard/Table';
import Select from 'components/Dashboard/Select';
import { useTranslation } from 'react-i18next';
import { forwardRef, useRef, useState } from 'react';
import useLanguage from 'hooks/useLanguage';
import ListPageTitle from '../ListPageTitle';
import { ReactComponent as EmptyTableIllustration } from 'assets/images/empty-table-illustration.svg';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { useForm } from 'react-hook-form';
import { adjustToUTC } from 'utils/date';

const BooleanFilters = ({ filters, onChange }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;

    onChange({ [name]: checked });
  };

  return filters.map(({ id, label }) => (
    <FormControl key={id} display="flex" alignItems="center" gap={2}>
      <Switch
        id={id}
        name={id}
        size="lg"
        colorScheme="primary"
        onChange={handleChange}
      />
      <FormLabel
        htmlFor={id}
        margin="0"
        whiteSpace="nowrap"
        fontSize="sm"
        fontWeight={600}
        color="gray.700"
      >
        {label}
      </FormLabel>
    </FormControl>
  ));
};

const EmptyTableForSearchOrFilter = ({ hasFilters, hasSearch }) => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={4}
      alignItems="center"
      textAlign="center"
      paddingTop={10}
      paddingBottom={14}
    >
      <EmptyTableIllustration />
      <Stack spacing={3}>
        <Text fontSize="2xl" fontWeight={700}>
          {t('noDataFound')}
        </Text>
        <Text color="gray.500" fontWeight={500}>
          {`${hasSearch ? t('tryDifferentSearch') : ''} ${
            hasSearch && hasFilters ? t('or') : ''
          } ${hasFilters ? t('uncheckSomeFilters') : ''}`}
        </Text>
      </Stack>
    </Stack>
  );
};

const getMultiselectValues = (values) => {
  return values.filter((opt) => opt.value !== '').map((opt) => opt.value);
};

const ClearSearchButton = ({ search, onClick }) => {
  if (!search) {
    return null;
  }

  return <CloseButton onClick={onClick} />;
};

export const SearchInput = ({ value, placeholder, onChange, disabled }) => {
  const { language } = useLanguage();

  const isRTL = language === 'ar';

  return (
    <InputGroup flex={1} minWidth={{ base: 'auto', md: '326px' }} size="lg">
      <InputLeftElement pointerEvents={null}>
        {!isRTL ? <SearchIcon /> : <ClearSearchButton />}
      </InputLeftElement>
      <Input
        bg="gray.50"
        border="none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderRadius="xl"
        paddingEnd={2}
        fontSize="sm"
        minWidth="250px"
        disabled={disabled}
      />
      <InputRightElement pointerEvents={null}>
        {isRTL ? <SearchIcon /> : <ClearSearchButton />}
      </InputRightElement>
    </InputGroup>
  );
};

const Filters = ({
  filters = [],
  filterColumns,
  selectedFilter,
  handleFilterChange,
  handleDateFilterChange,
  size,
  control,
  register,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const hasMoreThanThreeFilters = filters.length > 3;
  const inputsSize = size ?? (hasMoreThanThreeFilters ? 'md' : 'lg');

  const onDateFilterChange = (name, date) => {
    const value = date === null ? '' : adjustToUTC(date);
    handleDateFilterChange(name, value);
  };

  return (
    <SimpleGrid
      pt={{ base: 0, md: 0 }}
      columnGap={hasMoreThanThreeFilters ? 4 : 6}
      rowGap={hasMoreThanThreeFilters ? 3 : 6}
      columns={{ base: 1, md: filterColumns ?? filters.length }}
    >
      {filters.map(({ label, options, name, allLabel, type }, index) => {
        if (type === 'date') {
          const value = selectedFilter?.[name] || null;

          return (
            <DatePicker
              key={name}
              name={name}
              label={label}
              onChange={(date) => onDateFilterChange(name, date)}
              register={register}
              errors={{}}
              formValue={value}
              defaultValue={value}
              control={control}
              size={inputsSize}
              hideOptionalIndicator
              hidePortal
            />
          );
        }

        const allOptions = [
          {
            label: `${t('all')} ${!isRTL ? allLabel ?? label : ''}`,
            value: '',
          },
          ...(options ? options : []),
        ];

        const selectedOptions = allOptions.filter((option) =>
          selectedFilter?.[name]?.includes(option.value)
        );

        const value = selectedOptions.length ? selectedOptions : allOptions[0];

        return (
          <Box key={name}>
            <Select
              name={name}
              label={label}
              value={value}
              options={allOptions}
              onChange={handleFilterChange}
              size={inputsSize}
              isMulti
              required
            />
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

const FilterButton = forwardRef(({ onClick }, ref) => {
  const { t } = useTranslation();
  return (
    <Button
      leftIcon={<FilterIcon />}
      bg="gray.50"
      color="primary.500"
      px={6}
      onClick={onClick}
      textTransform="capitalize"
      size="lg"
      ref={ref}
    >
      {t('filter')}
    </Button>
  );
});

const FiltersAsPopover = ({
  filters = [],
  filterColumns = 2,
  onFilterSubmit,
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const popoverContentRef = useRef();
  const toggleButtonRef = useRef();

  const handleOnClose = () => {
    reset(lastSelectedFilter);
    onClose();
  };

  useOutsideClick({
    ref: popoverContentRef,
    handler: (event) => {
      if (
        event.target === toggleButtonRef.current ||
        toggleButtonRef.current.contains(event.target)
      ) {
        return;
      }
      handleOnClose();
    },
    enabled: isOpen,
  });

  const { handleSubmit, setValue, watch, reset, control, register } = useForm();
  const selectedFilter = watch();

  const [lastSelectedFilter, setLastSelectedFilter] = useState({});

  const onSubmit = (data) => {
    setLastSelectedFilter(data);
    onFilterSubmit(data);
    onClose();
  };

  const handleFilterChange = (values, actionMeta) => {
    if (actionMeta.option?.value === '') {
      setValue(actionMeta.name, '');
      return;
    }

    const value = getMultiselectValues(values);
    setValue(actionMeta.name, value);
  };

  const handleDateFilterChange = (name, date) => {
    setValue(name, date);
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen}>
      <PopoverTrigger>
        <Box>
          <FilterButton onClick={onToggle} ref={toggleButtonRef} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        minWidth={{ base: '100%', md: '570px' }}
        borderRadius="2xl"
        boxShadow="10px 15px 50px 0px rgba(113, 128, 150, 0.16)"
        border="none"
        ref={popoverContentRef}
      >
        <PopoverHeader
          padding={4}
          color="gray.800"
          fontSize="sm"
          fontWeight={500}
        >
          {t('applyFilters')}
        </PopoverHeader>
        <PopoverBody padding={0}>
          <Stack as="form" spacing={0} onSubmit={handleSubmit(onSubmit)}>
            <Box padding={4}>
              <Filters
                filters={filters}
                filterColumns={filterColumns}
                selectedFilter={selectedFilter}
                handleFilterChange={handleFilterChange}
                handleDateFilterChange={handleDateFilterChange}
                menuPortalTarget={popoverContentRef.current}
                control={control}
                register={register}
              />
            </Box>

            <Flex
              justifyContent="flex-end"
              gap={4}
              borderTop="1px solid"
              borderColor="gray.200"
              paddingX={4}
              paddingY={3}
              width="full"
            >
              <Button
                variant="outline"
                onClick={handleOnClose}
                minWidth={{ base: '47%', md: '150px' }}
              >
                {t('cancel')}
              </Button>

              <Button
                variant="primary"
                type="submit"
                minWidth={{ base: '47%', md: '150px' }}
              >
                {t('apply')}
              </Button>
            </Flex>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const PageFilter = ({
  pageFilter,
  pageFilterValue,
  handlePageFilterChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box minWidth="266px">
      <Select
        name="pageFilter"
        options={pageFilter}
        defaultValue={pageFilter[0]}
        onChange={handlePageFilterChange}
        value={pageFilterValue}
        background="white"
        isMulti
        components={{
          SingleValue: ({ children, ...props }) => (
            <chakraComponents.SingleValue {...props}>
              <Text color="gray.600" fontWeight="500" as="span">
                {`${t('show')}: `}
              </Text>
              <Text display="inline">{children}</Text>
            </chakraComponents.SingleValue>
          ),
        }}
      />
    </Box>
  );
};

const TablePage = ({
  title,

  pageFilter,
  onPageFilterChange,

  pageAction,
  tableAction,
  data = [],
  columns,

  filters = [],
  booleanFilters = [],
  selectedFilter,
  selectedPageFilter,
  onFilterChange,
  filterColumns,
  isFilterAsPopover,

  defaultSorting,
  enableSorting,
  onSortingChange,

  pagination = {},
  onPaginationChange,

  searchPlaceholder,
  onSearchChange,

  isLoading,
  hidePagination,
  hideSearch,

  footer,

  emptyTable,
  showTitleOnEmpty,
  showEmptyInsideBody,

  enableRowSelection,
  rowSelection,
  onRowSelectionChange,
}) => {
  const [showFilters, setShowFilters] = useBoolean();

  const [search, setSearch] = useState('');
  const [searchAfterTimeout, setSearchAfterTimeout] = useState('');

  const handleFilterChange = (values, actionMeta) => {
    if (actionMeta.option?.value === '') {
      onFilterChange({ [actionMeta.name]: '' });
      return;
    }

    const value = getMultiselectValues(values);

    onFilterChange({ [actionMeta.name]: value });
  };

  const handleDateFilterChange = (name, date) => {
    onFilterChange({ [name]: date });
  };

  const handlePageFilterChange = (values, actionMeta) => {
    if (actionMeta.option?.value === '') {
      onPageFilterChange('');
      return;
    }

    const value = getMultiselectValues(values);

    onPageFilterChange(value);
  };

  const handleChange = useDebouncedCallback((value) => {
    onSearchChange(value);
    setSearchAfterTimeout(value);
  }, 500);

  const hasPageFilters = !!pageFilter?.length;
  const hasFilters = hasPageFilters || !!filters.length;

  const hasSelectedSearchOrFilter = !!selectedFilter || !!searchAfterTimeout;
  const noDataAfterLoading = !data.length && !isLoading;

  const noData = noDataAfterLoading && !hasSelectedSearchOrFilter;
  const noDataForSearchOrFilter =
    noDataAfterLoading && hasSelectedSearchOrFilter;

  const showEmptyTable = noData && !!emptyTable;

  if (showEmptyTable && !showTitleOnEmpty && !showEmptyInsideBody) {
    return emptyTable;
  }

  const pageFilterValue = selectedPageFilter?.length
    ? pageFilter.filter((option) => selectedPageFilter?.includes(option.value))
    : pageFilter?.[0];

  return (
    <Stack spacing={6}>
      {(hasPageFilters || pageAction) &&
        (!showEmptyTable || showEmptyInsideBody) && (
          <ListPageTitle
            title={
              hasPageFilters && (
                <PageFilter
                  pageFilter={pageFilter}
                  pageFilterValue={pageFilterValue}
                  handlePageFilterChange={handlePageFilterChange}
                />
              )
            }
            action={pageAction}
          />
        )}

      <Stack
        bg="white"
        borderRadius="2xl"
        paddingX={{ base: 4, md: 6 }}
        paddingBottom={{ base: 0, md: 0 }}
        paddingTop={6}
        spacing={6}
      >
        <Flex
          gap={2}
          rowGap={4}
          alignItems={{ md: 'center' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          wrap="wrap"
        >
          {title && (
            <Heading
              fontSize="lg"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {title}
            </Heading>
          )}

          <Flex gap={4} flex={1} justifyContent="flex-end" wrap="wrap">
            {!!booleanFilters.length && (
              <Show below="md">
                <Flex
                  alignItems="center"
                  gap={4}
                  flex={1}
                  width="full"
                  direction={{ md: 'row', base: 'column' }}
                >
                  <BooleanFilters
                    filters={booleanFilters}
                    onChange={onFilterChange}
                  />
                </Flex>
              </Show>
            )}

            {(!showEmptyTable || showEmptyInsideBody) && (
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                {!hideSearch && (
                  <SearchInput
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleChange(e.target.value);
                    }}
                    placeholder={searchPlaceholder}
                  />
                )}

                {!!booleanFilters.length && (
                  <Hide below="md">
                    <Flex alignItems="center" gap={4} flex={1} width="full">
                      <BooleanFilters
                        filters={booleanFilters}
                        onChange={onFilterChange}
                      />
                    </Flex>
                  </Hide>
                )}

                {!!filters.length && (
                  <>
                    {isFilterAsPopover ? (
                      <FiltersAsPopover
                        filters={filters}
                        filterColumns={filterColumns}
                        onFilterSubmit={onFilterChange}
                      />
                    ) : (
                      <FilterButton onClick={setShowFilters.toggle} />
                    )}
                  </>
                )}

                {tableAction}
              </Flex>
            )}
          </Flex>
        </Flex>

        {showFilters &&
          !isFilterAsPopover &&
          (!showEmptyTable || showEmptyInsideBody) && (
            <Filters
              filters={filters}
              filterColumns={filterColumns}
              selectedFilter={selectedFilter}
              handleFilterChange={handleFilterChange}
              handleDateFilterChange={handleDateFilterChange}
            />
          )}

        {noDataForSearchOrFilter ? (
          <EmptyTableForSearchOrFilter
            hasFilters={hasFilters}
            hasSearch={!hideSearch}
          />
        ) : (
          <Box>
            {showEmptyTable && !showEmptyInsideBody ? (
              emptyTable
            ) : (
              <Table
                data={data}
                columns={columns}
                onSortingChange={onSortingChange}
                onPaginationChange={onPaginationChange}
                pageCount={pagination.totalPages}
                pageIndex={pagination.pageIndex}
                isLoading={isLoading}
                hidePagination={hidePagination || showEmptyTable}
                footer={footer}
                defaultSorting={defaultSorting}
                enableSorting={enableSorting}
                emptyTable={showEmptyTable && showEmptyInsideBody && emptyTable}
                enableRowSelection={enableRowSelection}
                rowSelection={rowSelection}
                onRowSelectionChange={onRowSelectionChange}
              />
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default TablePage;
