import { Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

const TruncatedText = forwardRef(
  ({ text, maxWidth = '160px', noOfLines = 1, ...props }, ref) => {
    return (
      <Text
        maxW={maxWidth}
        whiteSpace="normal"
        overflowWrap="break-word"
        wordBreak="break-word"
        noOfLines={noOfLines}
        title={text}
        {...props}
        ref={ref}
      >
        {text}
      </Text>
    );
  }
);

export default TruncatedText;
