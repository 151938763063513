import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useState } from 'react';
import TablePage from 'components/Dashboard/TablePage';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import EmptyTable from 'components/Dashboard/EmptyTable';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { roundNumber } from 'utils/number';
import { Controller, useForm } from 'react-hook-form';
import { NUMBER_MAX_VALUE } from 'constants/validation';

const columnHelper = createColumnHelper();

const OrderSKUCart = ({ skus, onSKUDelete, onSKUChange, onSKUSubmit }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });
  const queryClient = useQueryClient();

  const [editSKUId, setEditSKUId] = useState(undefined);

  const handleSkuChange = (id, { key, value }) => {
    onSKUChange(id, { key, value });
  };

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    const isSKUAlreadyInCart = skus.some((item) => item.id === sku.sku_id);

    if (isSKUAlreadyInCart) {
      toast({
        title: t('SKU already in cart'),
        status: 'error',
      });
      return;
    }

    const selectedSKUData = queryClient
      .getQueriesData(['skus'])[0]?.[1]
      ?.data.find((skuData) => skuData.id === sku.sku_id);

    const selectedSKU = {
      ...selectedSKUData,
      quantity: +sku.quantity,
      price: +sku.price,
    };

    onSKUSubmit(selectedSKU);
    onSuccessCallback();
  };

  const { control, trigger } = useForm();

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => (
        <Text fontWeight={600} color="gray.800">
          {getValue()}
        </Text>
      ),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('system_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={500} color="gray.800">
          {getValue()}
        </Text>
      ),
      header: t('sirdabID'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();

          const maxQuantity = row.original.on_hand.units;
          const name = `${row.original.id}-quantity`;

          return (
            <Box maxWidth={{ base: '90px', md: '100px' }}>
              <Controller
                control={control}
                name={name}
                rules={{
                  required: t('thisFieldIsRequired'),
                  max: {
                    value: maxQuantity,
                    message: `${t('maxIs')} ${maxQuantity}`,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DebouncedNumberInput
                    placeholder="quantity"
                    defaultValue={defaultValue}
                    min={1}
                    max={maxQuantity}
                    errorMessage={error?.message}
                    value={value}
                    onChange={async (value) => {
                      onChange(value);
                      await trigger(name);

                      handleSkuChange(id, {
                        key: 'quantity',
                        value: +value,
                      });
                    }}
                  />
                )}
              />
            </Box>
          );
        }
        return (
          <Text color="gray.800" fontWeight={500}>
            {getValue()}
          </Text>
        );
      },
      header: t('quantity'),
    }),
    columnHelper.accessor('price', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();
          const name = `${row.original.id}-price`;

          return (
            <Box maxWidth={{ base: '130px', md: '150px' }}>
              <Controller
                control={control}
                name={name}
                rules={{
                  required: t('thisFieldIsRequired'),
                  max: {
                    value: NUMBER_MAX_VALUE,
                    message: `${t('maxIs')} ${NUMBER_MAX_VALUE}`,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DebouncedNumberInput
                    placeholder={t('unitPrice')}
                    defaultValue={defaultValue}
                    min={1}
                    max={NUMBER_MAX_VALUE}
                    errorMessage={error?.message}
                    onChange={async (value) => {
                      onChange(value);
                      await trigger(name);

                      handleSkuChange(id, {
                        key: 'price',
                        value,
                      });
                    }}
                  />
                )}
              />
            </Box>
          );
        }
        return (
          <Center color="gray.800" fontWeight={500}>{`${roundNumber(
            getValue()
          )} ${t('SAR')}`}</Center>
        );
      },
      header: t('unitPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ row }) => {
        const { quantity, price } = row.original;
        const total = roundNumber(price * quantity);

        return (
          <Text fontWeight={500} textAlign="center">{`${total} ${t(
            'SAR'
          )}`}</Text>
        );
      },
      header: t('totalPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;

        const handleDelete = () => {
          onSKUDelete(row.original.id);
        };

        const handleEdit = () => {
          setEditSKUId((prevValue) => (prevValue === id ? undefined : id));
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton as={IconButton} icon={<DotsVerticalIcon />} />
              <MenuList>
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <Flex gap={3} justifyContent="center">
            <Button
              variant="outline"
              color="gray.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleEdit}
            >
              {t('edit')}
            </Button>
            <Button
              variant="outline"
              color="red.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleDelete}
            >
              {t('remove')}
            </Button>
          </Flex>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
    }),
  ];

  return (
    <TablePage
      title={
        <SectionTitle title={`${t('skuCart')} (${skus.length})`} hideDivider />
      }
      data={skus}
      columns={columns}
      footer={
        <Box pb={6} mt={2}>
          <SKUsTableForm onSubmit={handleSkuSubmit} isEcommerce />
        </Box>
      }
      emptyTable={
        <EmptyTable
          illustration={<SKUsEmptyTable />}
          title={t('noSKUsYet')}
          subtitle={`${t('createNewSKU')} ${t('or')} ${t('selectExisting', {
            value: t('theInbound'),
          })}`}
          noPadding
        />
      }
      showEmptyInsideBody
      hideSearch
      hidePagination
      enableSorting={false}
    />
  );
};

export default OrderSKUCart;
