import {
  Stack,
  Card,
  CardHeader,
  CardBody,
  useDisclosure,
  Text,
  Flex,
  useToast,
  Box,
  Spinner,
  Button,
  Center,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserSquareIcon } from 'assets/icons/user-square.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/sms.svg';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import SectionDataItem from 'components/Dashboard/SectionDataItem';
import PageDrawer from 'components/Dashboard/PageDrawer';
import CustomerForm from 'components/Dashboard/Fulfillment/Customers/CustomerForm';
import useTable from 'hooks/useTable';
import {
  addCustomerAddress,
  getCustomerDetails,
  updateCustomer,
} from 'api/Dashboard/fulfillment/customers';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import BackToListButton from 'components/Dashboard/BackToListButton';
import { OrdersTablePage } from '../Orders/Index';
import { getOrders } from 'api/Dashboard/fulfillment/orders';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import { getCustomerAddresses } from 'api/Dashboard/fulfillment/customers';
import { generateCustomerAddress } from 'utils/fulfillment';
import { valueFallback } from 'utils/string';
import { AddIcon } from '@chakra-ui/icons';
import AddressDrawer from 'components/Dashboard/Fulfillment/Orders/AddressDrawer';

const addressColumnHelper = createColumnHelper();

const CustomerOrders = ({ customerId }) => {
  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: (searchParams) => {
      const searchParamsWithCustomerId = `${searchParams}&q[customer_id_eq]=${customerId}`;

      return getOrders(searchParamsWithCustomerId);
    },
    fetchKey: customerId
      ? `fulfillment-orders-${customerId}`
      : 'fulfillment-orders',
    searchKey: 'identifier_or_channel_order_id_cont',
    useRansack: true,
  });

  return (
    <OrdersTablePage
      data={data}
      pagination={pagination}
      responseFilters={responseFilters}
      selectedFilter={selectedFilter}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      isCustomerPage
    />
  );
};

const CustomerAddresses = ({ customerId }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data,
    isLoading,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
    refetch,
    isRefetching,
  } = useTable({
    fetch: (searchParams) => getCustomerAddresses(customerId, searchParams),
    fetchKey: customerId
      ? `customer-addresses-${customerId}`
      : 'customer-addresses',
    searchKey: 'district_name_or_street_name_or_building_number_cont',
  });

  const columns = [
    addressColumnHelper.accessor('address', {
      cell: ({ row }) => {
        return generateCustomerAddress(row.original, true);
      },
      header: t('address'),
      meta: {
        mobileHeader: 'left',
        fitContent: true,
      },
    }),
    addressColumnHelper.accessor('zip_code', {
      cell: ({ getValue }) => <Center>{valueFallback(getValue())}</Center>,
      header: t('zipCode'),
      meta: {
        centerHeader: true,
      },
    }),
    addressColumnHelper.accessor('city.name', {
      cell: ({ row }) => {
        const value = valueFallback(row.original.city.name);
        return <Center>{value}</Center>;
      },
      header: t('city'),
      meta: {
        centerHeader: true,
      },
    }),
    addressColumnHelper.accessor('city.country_name', {
      cell: ({ row }) => {
        const value = valueFallback(row.original.city.country_name);

        return <Center>{value}</Center>;
      },
      header: t('country'),
      meta: {
        centerHeader: true,
      },
    }),
  ];

  const { mutate: addAddress, isLoading: isAddAddressLoading } = useMutation(
    (address) => addCustomerAddress(customerId, address),
    {
      onSuccess: async (response) => {
        toast({
          title: t('addressAddedSuccessfully'),
          status: 'success',
        });
        await refetch();
        onClose();
      },
      onError: (error) => {
        toast({
          title: error?.response?.data?.message || t('somethingWentWrong'),
          status: 'error',
        });
      },
    }
  );

  const handleAddressSubmit = (data) => {
    const payload = {
      city_id: data.city_id,
      district_name: data.district_name,
      street_name: data.street_name,
      building_number: data.building_number,
      zip_code: data.zip_code,
      additional_details: data.additional_details,
    };

    addAddress(payload);
  };

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        pagination={pagination}
        title={t('addresses')}
        tableAction={
          <Button
            colorScheme="primary"
            size="lg"
            leftIcon={<AddIcon />}
            onClick={onOpen}
          >
            {t('createNewAddress')}
          </Button>
        }
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        onSearchChange={onSearchChange}
        isLoading={isLoading || isRefetching}
        enableSorting={false}
        hideSearch
        hidePagination
      />

      {isOpen && (
        <AddressDrawer
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleAddressSubmit}
          isLoading={isAddAddressLoading}
        />
      )}
    </>
  );
};

const CustomerDetails = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const toast = useToast();

  const {
    data: customerResponse,
    refetch,
    isLoading,
  } = useQuery(['fulfillment-customer', id], () => getCustomerDetails(id), {
    enabled: !!id,
  });

  const {
    mutate: updateCustomerMutation,
    isLoading: isUpdateCustomerLoading,
    error: updateCustomerError,
  } = useMutation((data) => updateCustomer(id, data), {
    onSuccess: () => {
      onClose();
      refetch();
      toast({
        title: t('customers.updateSuccess'),
        status: 'success',
        duration: 5000,
      });
    },
    onError: () => {
      toast({
        title: t('customers.updateFailed'),
        status: 'error',
        duration: 5000,
      });
    },
  });

  if (isLoading) {
    return <Spinner size="lg" color="primary.500" />;
  }

  const customerDetails = customerResponse?.data;

  const customerDetailsItems = [
    {
      icon: <UserSquareIcon width="24px" height="24px" />,
      label: t('customerName'),
      value: customerDetails?.full_name,
    },
    {
      icon: <MobileIcon width="24px" height="24px" />,
      label: t('phoneNumber'),
      value: customerDetails?.phone,
    },
    {
      icon: <EmailIcon width="24px" height="24px" />,
      label: t('email'),
      value: customerDetails?.email,
    },
  ];

  const handleUpdateCustomer = (values) => {
    updateCustomerMutation({
      phone: values.phone,
      email: values.email,
    });
  };

  return (
    <Stack spacing={6}>
      <Box>
        <BackToListButton
          title={t('customers.customers')}
          url="/fulfillment/customers"
        />
      </Box>
      <Card size="lg">
        <CardHeader>
          <SectionTitle
            title={
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text as="span">{t('customers.customerDetails')}</Text>
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="primary"
                  onClick={onOpen}
                >
                  {t('edit')}
                </Button>
              </Flex>
            }
          />
        </CardHeader>
        <CardBody>
          <Stack spacing={4}>
            {customerDetailsItems.map(({ icon, label, value }, index) => (
              <SectionDataItem
                key={index}
                icon={icon}
                label={label}
                value={value}
              />
            ))}
          </Stack>
        </CardBody>
      </Card>

      <CustomerOrders customerId={id} />

      <CustomerAddresses customerId={id} />

      <PageDrawer
        title={t('customers.editCustomer')}
        isOpen={isOpen}
        onClose={onClose}
        formId="customerForm"
        isLoading={isUpdateCustomerLoading}
      >
        <CustomerForm
          defaultValues={customerDetails}
          onSubmit={handleUpdateCustomer}
          isLoading={isUpdateCustomerLoading}
          backendErrors={updateCustomerError?.response?.data?.errors}
        />
      </PageDrawer>
    </Stack>
  );
};

export default CustomerDetails;
