import {
  Card,
  CardHeader,
  CardBody,
  Stack,
  HStack,
  Text,
  Box,
  List,
  ListItem,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import WithAddNewButton from 'components/Dashboard/WithAddNewButton';
import { getCustomerAddresses } from 'api/Dashboard/fulfillment/customers';
import { useQueryClient } from 'react-query';
import useLanguage from 'hooks/useLanguage';
import { valueFallback } from 'utils/string';
import { ReactComponent as GlobalIcon } from 'assets/icons/global.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as MapLocationIcon } from 'assets/icons/map-location.svg';
import { ReactComponent as ZipCodeIcon } from 'assets/icons/zip-code.svg';
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/location-outline.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';

const AddressDetails = ({
  customerId,
  addressId,
  control,
  errors,
  isDisabled,
  onAddNewClick,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const entityKey = `customer-addresses-${customerId}`;

  const address = queryClient
    .getQueryData(entityKey)
    ?.data?.find((address) => address.id === addressId);

  const addressFields = [
    {
      icon: <GlobalIcon />,
      label: t('country'),
      value: valueFallback(address?.city?.country_name),
    },
    {
      icon: <MapPinIcon />,
      label: t('city'),
      value: valueFallback(address?.city?.[isAr ? 'nameLocale' : 'name']),
    },
    {
      icon: <MapLocationIcon />,
      label: t('districtName'),
      value: valueFallback(address?.district_name),
    },
    {
      icon: <LocationOutlineIcon />,
      label: t('streetName'),
      value: valueFallback(address?.street_name),
    },
    {
      icon: <BuildingIcon />,
      label: t('buildingNumber'),
      value: valueFallback(address?.building_number),
    },
    {
      icon: <ZipCodeIcon />,
      label: t('zipCode'),
      value: valueFallback(address?.zip_code),
    },
    {
      icon: <LocationOutlineIcon />,
      label: t('additionalDetails'),
      value: valueFallback(address?.additional_details),
    },
  ];

  return (
    <Card size="lg" height="100%">
      <CardHeader>
        <SectionTitle title={t('addressesPage.addressDetails')} />
      </CardHeader>
      <CardBody>
        <Stack spacing={6}>
          <WithAddNewButton onClick={onAddNewClick} isDisabled={isDisabled}>
            <Controller
              control={control}
              name="address_id"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  performSearchRequest={async () => {
                    const response = await getCustomerAddresses(customerId);

                    const options = [...response.data]?.map((address) => ({
                      ...address,
                      name: `${address.street_name}, ${
                        address.district_name
                      }, ${address.city?.[isAr ? 'nameLocale' : 'name']}`,
                    }));

                    return { data: options };
                  }}
                  entityKey={entityKey}
                  arLabelKey="name"
                  label={t('address')}
                  placeholder={t('pleaseSelectAddress')}
                  onChange={(option) => onChange(option?.value)}
                  value={value}
                  required="thisFieldIsRequired"
                  error={errors?.address_id}
                  disabled={isDisabled}
                  enableFetch={!!customerId}
                />
              )}
            />
          </WithAddNewButton>

          <Box>
            {address && (
              <List spacing={4}>
                {addressFields.map(
                  (field, index) =>
                    field.value && (
                      <ListItem key={index}>
                        <Flex gap={3} width="100%" justify="space-between">
                          <HStack spacing={1}>
                            {field.icon && (
                              <Box color="gray.500">{field.icon}</Box>
                            )}
                            <Text
                              fontSize="sm"
                              fontWeight={500}
                              color="gray.600"
                              textTransform="capitalize"
                            >
                              {field.label}
                            </Text>
                          </HStack>
                          <Text fontWeight={600}>{field.value}</Text>
                        </Flex>
                      </ListItem>
                    )
                )}
                {address?.additional_comments && (
                  <ListItem>
                    <Stack spacing={1}>
                      <Text
                        fontSize="sm"
                        fontWeight={500}
                        color="gray.500"
                        textTransform="capitalize"
                      >
                        {t('additionalDetails')}
                      </Text>
                      <Text fontWeight={600}>
                        {address.additional_comments}
                      </Text>
                    </Stack>
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AddressDetails;
