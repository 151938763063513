import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Hide,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { ReactComponent as Global } from 'assets/icons/global.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SidebarIcon } from 'assets/icons/sidebar.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question-circle-outlined.svg';
import LanguageSelect from 'components/shared/Inputs/LanguageSelect';
import useAuth from 'hooks/useAuth';
import useLanguage from 'hooks/useLanguage';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/localStorage';
import PageTitle from './PageTitle';
import Notifications from './Notifications';
import useIsMobile from 'hooks/useIsMobile';

const SearchBoxSelectWidth = '80px';

const SearchBoxSelect = () => (
  <Select
    defaultValue="sku"
    border="none"
    width={SearchBoxSelectWidth}
    borderRadius="0"
    background="transparent"
    fontWeight={500}
    fontSize="sm"
    color="gray.800"
  >
    <option value="sku">SKU</option>
  </Select>
);

const SearchBox = ({ placeholder, isAr }) => {
  const isMobile = useIsMobile();

  const { onToggle, isOpen, onClose } = useDisclosure({
    defaultIsOpen: !isMobile,
  });

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: isMobile ? onClose : undefined,
  });

  return (
    <InputGroup ref={ref} size="lg">
      <InputLeftElement {...(isAr ? { width: 'auto' } : {})}>
        {isAr ? (
          isOpen ? (
            <SearchBoxSelect />
          ) : null
        ) : (
          <SearchIcon onClick={isMobile ? onToggle : undefined} />
        )}
      </InputLeftElement>

      <Input
        border="none"
        paddingEnd={isOpen ? SearchBoxSelectWidth : 0}
        width={isOpen ? '100%' : '40px'}
        borderRadius={isOpen ? 'xl' : 'full'}
        placeholder={placeholder}
      />

      <InputRightElement {...(!isAr ? { width: 'auto' } : {})}>
        {isAr ? (
          <SearchIcon onClick={isMobile ? onToggle : undefined} />
        ) : isOpen ? (
          <SearchBoxSelect />
        ) : null}
      </InputRightElement>
    </InputGroup>
  );
};

const StyledLogoutIcon = styled(Logout)`
  path {
    stroke: #e53e3e;
  }
`;

const Navbar = ({ onToggle, height, pageTitle, isCreatePage, ...rest }) => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const { signOut } = useAuth();
  const user = getLocalStorage('user') ?? {};

  const isMobile = useIsMobile();

  const isAr = language === 'ar';

  const toggleLanguage = () => {
    const newLang = isAr ? 'en' : 'ar';
    changeLanguage(newLang);
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Flex
      px={{ base: 4, md: 8 }}
      py={{ base: 4, md: 6 }}
      // height={height}
      alignItems="center"
      bg="white"
      justifyContent="space-between"
      gap={{ md: 4, base: 2 }}
      zIndex={2}
      {...rest}
    >
      <Flex alignItems="center" gap="2">
        <Hide above="md">
          <IconButton
            onClick={onToggle}
            icon={<SidebarIcon />}
            variant="ghost"
          />
        </Hide>
        <PageTitle title={pageTitle} display={{ base: 'none', md: 'inline' }} />
      </Flex>
      <HStack
        spacing={{ base: '2', md: 6 }}
        visibility={isCreatePage && !isMobile ? 'hidden' : 'initial'}
      >
        {!isCreatePage && (
          <Hide below="md">
            <Button
              color="gray.700"
              as="a"
              href={`https://airtable.com/app4pShtVmQecBM59/shrePZhXaaYgl37X3?prefill_Name=${user.name}`}
              target="_blank"
              size="lg"
              fontSize="sm"
              leftIcon={<StarIcon />}
            >
              {t('rateUsAndGet50Sar')}
            </Button>
            <Button
              color="gray.700"
              size="lg"
              fontSize="sm"
              onClick={() => window.Intercom('showSpace', 'help')}
              leftIcon={<QuestionIcon />}
            >
              {t('help')}
            </Button>
            {/* <SearchBox placeholder={`${t('Search')}...`} isAr={isAr} /> */}
          </Hide>
        )}
        <Box>
          <LanguageSelect
            borderRadius="xl"
            display={{ base: 'none', md: 'flex' }}
            size="lg"
            fontWeight={700}
            fontSize="sm"
            color="gray.800"
          />
        </Box>
        <Notifications />
        <Flex alignItems="center">
          <Menu>
            <MenuButton transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  name={user?.name}
                  src={user?.avatar}
                  bg="primary.700"
                  color="white"
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <ChevronDownIcon />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200" paddingX="2">
              {isMobile && (
                <MenuItem
                  icon={<StarIcon width="22" height="22" />}
                  as="a"
                  href={`https://airtable.com/app4pShtVmQecBM59/shrePZhXaaYgl37X3?prefill_Name=${user.name}`}
                  target="_blank"
                >
                  {t('rateUsAndGet50Sar')}
                </MenuItem>
              )}
              {isMobile && (
                <MenuItem
                  onClick={() => window.Intercom('showSpace', 'help')}
                  icon={<QuestionIcon />}
                >
                  {t('help')}
                </MenuItem>
              )}
              <MenuItem
                icon={<Settings width="22" height="22" />}
                as={Link}
                to="settings"
              >
                {t('settings')}
              </MenuItem>
              <MenuItem
                display={{ base: 'flex', md: 'none' }}
                icon={<Global width="22" height="22" />}
                onClick={toggleLanguage}
              >
                {isAr ? 'English' : 'العربية'}
              </MenuItem>
              <MenuItem
                borderRadius="md"
                icon={<StyledLogoutIcon />}
                color="#E53E3E"
                onClick={handleSignOut}
              >
                {t('logout')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default Navbar;
