import {
  Box,
  Button,
  Center,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import {
  getCustomers,
  createCustomer,
} from 'api/Dashboard/fulfillment/customers';
import ActionsButton from 'components/Dashboard/ActionsButton';
import PageDrawer from 'components/Dashboard/PageDrawer';
import CustomerForm from 'components/Dashboard/Fulfillment/Customers/CustomerForm';
import { useMutation } from 'react-query';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as EmptyCustomersIllustration } from 'assets/images/customers-empty-table-illustration.svg';

const columnHelper = createColumnHelper();

const Customers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data,
    isLoading,
    isRefetching,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
    refetch,
  } = useTable({
    fetch: getCustomers,
    fetchKey: 'fulfillment-customers',
    searchKey: 'identifier_or_first_name_or_last_name_or_phone_or_email_cont',
  });

  const {
    mutate: createCustomerMutation,
    isLoading: isCreateCustomerLoading,
    error: createCustomerError,
  } = useMutation(createCustomer, {
    onSuccess: () => {
      onClose();
      refetch();
      toast({
        title: t('customers.createSuccess'),
        status: 'success',
        duration: 5000,
      });
    },
    onError: () => {
      toast({
        title: t('customers.createFailed'),
        status: 'error',
        duration: 5000,
      });
    },
  });

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('full_name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
    }),
    columnHelper.accessor('phone', {
      cell: ({ getValue }) => (
        <Text as="span" dir="ltr">
          {getValue()}
        </Text>
      ),
      header: t('phone'),
    }),
    columnHelper.accessor('email', {
      cell: ({ getValue }) => (
        <Text
          maxW="350px"
          whiteSpace="normal"
          overflowWrap="break-word"
          wordBreak="break-word"
        >
          {getValue()}
        </Text>
      ),
      header: t('email'),
    }),
    columnHelper.accessor('order_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('totalOrders'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton onClick={() => navigate(`${row.original.id}`)}>
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        pagination={pagination}
        title={t('fulfillmentCustomers')}
        pageAction={
          <Button
            leftIcon={<AddIcon />}
            colorScheme="primary"
            size="lg"
            onClick={onOpen}
          >
            {t('customers.createNewCustomer')}
          </Button>
        }
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('customers.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading || isRefetching}
        emptyTable={
          <EmptyTable
            title={t('customers.emptyCustomers')}
            subtitle={t('customers.emptyCustomersDescription')}
            illustration={
              <Box marginBottom="-35px">
                <EmptyCustomersIllustration />
              </Box>
            }
            noPadding
            maxWidth="600px"
          />
        }
        showEmptyInsideBody
      />

      <PageDrawer
        title={t('customers.createNewCustomer')}
        isOpen={isOpen}
        onClose={onClose}
        formId="customerForm"
        isLoading={isCreateCustomerLoading}
      >
        <CustomerForm
          onSubmit={(values) => createCustomerMutation(values)}
          isLoading={isCreateCustomerLoading}
          backendErrors={createCustomerError?.response?.data?.errors}
        />
      </PageDrawer>
    </>
  );
};

export default Customers;
