import { api } from 'utils/axios';

export const getCustomers = async (searchParams) => {
  try {
    const response = await api(`/fulfillment_customers?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getCustomerDetails = async (id) => {
  try {
    const response = await api.get(`/fulfillment_customers/${id}`);
    const { data } = response;

    return { data: data.data };
  } catch (error) {
    throw error;
  }
};

export const createCustomer = async (fulfillment_customer) => {
  try {
    const response = await api.post('/fulfillment_customers', {
      fulfillment_customer,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (id, fulfillment_customer) => {
  try {
    const response = await api.put(`/fulfillment_customers/${id}`, {
      fulfillment_customer,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const addCustomerAddress = async (
  customerId,
  fulfillment_customer_address
) => {
  try {
    const response = await api.post(
      `/fulfillment_customers/${customerId}/addresses`,
      {
        fulfillment_customer_address,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getCustomerAddresses = async (customerId, searchParams) => {
  try {
    const response = await api(
      `/fulfillment_customers/${customerId}/addresses?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
